import { empty, uuid } from '/@/shared/utils'

export default {
  props: {
    name: {
      type: String,
      required: false,
    },
    value: {
      type: String | Number,
      required: false,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    settings: {
      type: Object,
      required: false,
    },
    options: {
      type: Object | Array,
      required: false,
    },
    type: {
      type: String | Number,
      default: 'text',
    },
  },
  data() {
    return {
      id: uuid(),
    }
  },
  methods: {
    emitValue(newValue) {
      this.emitValueForVmodelSupport(newValue)
      this.emitNameValuePair(this.type, newValue)
    },
    emitValueForVmodelSupport(value) {
      this.$emit('input', value)
    },
    emitNameValuePair(type, value) {
      this.$emit('change', {
        type,
        value,
      })
    },
    emitSetting(settingName, settingValue) {
      this.$emit('setting', {
        attribute: this.type,
        type: settingName,
        value: settingValue,
      })
    },
  },
  computed: {
    isDefaultSlotVisible() {
      return !empty(this.$slots.default)
    },
    hasSettings() {
      return !empty(this.settings)
    },
    hasOptions() {
      return !empty(this.options)
    },
    hasTitle() {
      return !empty(this.title)
    },
  },
}
