import mixinInput from '/@mixins/input'
import { empty } from '/@/shared/utils'
import get from 'lodash.get'

export default {
  props: {
    debounce: {
      default: 0,
      type: Number,
    },
    placeholder: {
      default: '',
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    pattern: {
      type: String,
      default: '',
    },
    errorState: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      debounceHandler: null,
      isInErrorState: false,
      localValue: '',
    }
  },
  mixins: [mixinInput],
  computed: {
    inputType() {
      return get(this.settings, 'inputType', 'text')
    },
    hasPattern() {
      return !empty(this.pattern)
    },
    isValueEqualToLocalValue() {
      return this.value === this.localValue
    },
  },
  watch: {
    localValue(value) {
      this.validate(value)

      if (!this.isInErrorState && !this.isValueEqualToLocalValue) {
        this.debounceEmit(value)
      }
    },
    value: {
      immediate: true,
      handler(value) {
        this.localValue = value
      },
    },
    errorState: {
      immediate: true,
      handler() {
        this.isInErrorState = this.errorState
      },
    },
  },
  methods: {
    emitFocusEvent() {
      this.$emit('focus')
    },
    focus() {
      this.$refs.input.focus()
    },
    validate(value) {
      if (empty(value)) {
        this.isInErrorState = false
        return
      }

      if (!this.hasPattern) {
        return
      }

      const test = `^${this.pattern}+$`
      const regex = RegExp(test)
      this.isInErrorState = !regex.test(value)
    },
    debounceEmit(value) {
      if (this.debounce === 0) {
        return this.emitValue(value)
      }

      clearTimeout(this.debounceHandler)
      this.debounceHandler = setTimeout(() => {
        this.emitValue(value)
      }, this.debounce)
    },
  },
}
